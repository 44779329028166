import { useState, useEffect } from "react";

import './Process.css';

import { colorIcon } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgAddNewData, SvgCancel, SvgDelete, SvgEdit, SvgEye, SvgHistoric, SvgSearch, SvgUser, SvgUserDisabled } from "services/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Process(props){

    const [ search, setSearch ]     = useState('');
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ showData, setShowData ] = useState(GetDataPage('process'));
    const [ listUser, setListUser ] = useState(InitialData('all'));
    const [ listOff, setListOff ]   = useState(InitialData('deleted'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(listUser.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = listUser.slice(startItens, endItens);

    function InitialData(type){
        if(type == "all"){
            const newData = GetDataPage('process').filter(item => item.status == 1);
            return newData;
        }
        if(type == "deleted"){
            const newData = [];
            GetDataPage('process').map((elem, index)=>{
                if(elem.status == 0){
                    newData.push({ "id": elem.id, "type": "process", "title": elem.title, "removed_name": elem.removed_name, "removed_date": elem.removed_date })
                }
            });
            return newData;
        }
    }
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('process').forEach(item =>{
                if(item.status !=2){
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.note.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.year.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.month.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListUser(duplicate);
        }else {
            setListUser(InitialData('all'));
        }
        setSearch(value);
    }

    function AltAccess(status, id, value){
        SetModalData('Confirmation', { "origin": "process", "type" : "alt_status_data", "status": status, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'process_details');
        SetListPag('currentPageId', idPage);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('process', setShowData);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('process', setShowData);
        };
    }, []);

    useEffect(()=>{
        setListUser(InitialData('all'));
        setListOff(InitialData('deleted'));
    }, [showData]);

    return(
        <div className="Page_Process">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="list_opt_alt_page">
                    {
                        listOff.length > 0 ? 
                        <div className="" onClick={ ()=>{ SetModalData('Historic', { "listData": listOff, "type": "alt_status_data" } ); SetModalState('Historic', true); }}>
                            <SvgHistoric className="icons" color={ colorIcon }/>
                        </div> : null
                    }
                    {
                        props.typeAccess == "edit" ? 
                        <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                            <div className="new_data_add">
                                <SvgAddNewData className="icons" color="#ffffff" />
                            </div>
                            <div className="name_btn_add">Processo</div>
                        </div>
                        : null
                    }
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th>Cliente</th>
                            <th className="td_hide" width="40" align="center">Ano</th>
                            <th className="td_hide" width="40" align="center">Mês</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td>{ elem.name_client }</td>
                                        <td className="td_hide" align="center">{ elem.year }</td>
                                        <td className="td_hide" align="center">{ elem.month }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                {
                                                    props.typeAccess == "edit" ? 
                                                    <>
                                                        <div onClick={ ()=>{ AltAccess(0, elem.id, elem.title) } } data-tooltip-id={ "id_delete_" + elem.id } data-tooltip-content="Deletar processo" data-tooltip-place="top">
                                                            <SvgDelete color="#F00000" className="icons"/>
                                                        </div>
                                                        <Tooltip id={ "id_delete_" + elem.id } />
                                                    </>
                                                    : null
                                                }

                                                <div onClick={ ()=>{ PageClick(elem.id) } } data-tooltip-id={ "id_edit_" + elem.id } data-tooltip-content="Visualizar processo" data-tooltip-place="top">
                                                    <SvgEye color={ colorIcon } className="icons"/>
                                                </div>
                                                <Tooltip id={ "id_edit_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 6 }>Nenhum processo registrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    listUser.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}