
import { useState, useEffect } from "react";

import './Recover.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import { CodeNewPass } from "services/RegisterData";

export default function Recover(props){

    const [ text, setText ]         = useState('');
    const [ password, setPassword ] = useState('');
    const [ copyPass, setCopyPass ] = useState('');

    function saveData(event){
        props.setLoading(true);
        event.preventDefault();
        CodeNewPass('alt_password', password, props.code, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setTimeout(() => {
            setPassword('');
            setCopyPass('');
            props.setLoading(false);
            SetModalData('ReturnResponse', { "page": "ok", "type": "login" });
            SetModalState('ReturnResponse', true);
            
            window.history.pushState("", "", "/");
            props.setUrl(window.location.href.split("/"));
            SetListPag('page', 'index');
        }, 1000);
    }

    function CallbackError(){
        setTimeout(() => {
            props.setLoading(false);
            SetModalData('ReturnResponse', { "page": "erro", "type": "" });
            SetModalState('ReturnResponse', true);
        }, 1200);        
    }

    function CheckedPassword(){
        if(copyPass !=''){
            if(password == copyPass){
                return(
                    <div className="div_logar">
                        <button type="submit" className="button">Salvar</button>
                    </div>                    
                )
            }else {
                return(
                    <div className="msg_error">As senhas devem ser iguais!</div>
                )
            }
        }else {
            return(
                <div className="msg_error">As senhas acima devem ser iguais!</div>
            )
        }
    }

    function CheckedCode(){        
        CodeNewPass('checked_code', '', props.code, ()=>{ }, ()=>{ CodeInvalid() });
    }

    function CodeInvalid(){
        setText('Este link já foi utilizado, favor solicitar um novo na página de login!');
    }

    useEffect(()=>{
        CheckedCode();
    }, []);

    useEffect(()=>{
        CheckedPassword();

        document.title = 'Recuperar senha';
    }, [copyPass]);

    return(
        <form onSubmit={ saveData } className="Recover">
            <div className="content">
                <div className="div_logo">
                    <img alt="logotipo" src="./assets/logo.png" className="logo" />
                </div>
                {
                    text != "" ? 
                    <>
                        <div className="msg_error">{ text }</div> 
                        <div className="div_logar" onClick={ ()=>{ SetListPag('page', 'index'); window.history.pushState("", "", "/"); props.setUrl(window.location.href.split("/")) } }>
                            <div className="button">Voltar para login</div>
                        </div>
                    </>
                    :
                    <>
                        <div className="div_input">
                            <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } minLength={ 6 } placeholder="****" value={ password } required />
                            <div className="name_input">Nova senha</div>
                        </div>

                        <div className="div_input div_input_login">
                            <input type="password" onChange={ (e)=>{ setCopyPass(e.target.value) } } minLength={ 6 } placeholder="****" value={ copyPass } required />
                            <div className="name_input">Digite novamente a senha</div>
                        </div>
                        
                        {
                            CheckedPassword()
                        } 
                    </>
                }
            </div>
        </form>
    )
}
