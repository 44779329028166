import React, { useState, useEffect } from "react";

import './Historic.css';

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { SvgClose } from "services/SvgFile";
import { Reg_AltStatus } from "services/RegisterData";

export default function PopUP_Historic(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ listData, setListData ] = useState([]);

    function AltStatus(index, origin, id){
        const newData = [...listData];
        newData.splice(index, 1);
        setListData(newData);

        props.setLoading(true);
        Reg_AltStatus(userId, modalData.type, id, origin, 1, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });

        if(newData.length == 0){
            ClosePopUp();
        }
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('Historic', false);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        
        RegisterModalData('Historic', setModaldata);
        RegisterModalObserver('Historic', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setListData(modalData.listData);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all historic">
                    <div className="div_data type_div">
                        <div className="title">
                            Dados removidos
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th align="center" width="20">#</th>
                                        <th align="left">Nome</th>
                                        <th align="left" width="110">Data removido</th>
                                        <th align="left" width="110">Quem removeu</th>
                                        <th align="right" width="20">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listData.map((elem, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td align="center">{ index + 1 }</td>
                                                    <td align="left">{ elem.title }</td>
                                                    <td align="left">{ elem.removed_date }</td>
                                                    <td align="left">{ elem.removed_name }</td>
                                                    <td align="right">
                                                        <div className="btn_alt" onClick={ ()=>{ AltStatus(index, elem.type, elem.id) } }>
                                                            Reativar
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
