import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { colorIcon, config, listMonth } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { SvgDownload, SvgSave, SvgSeta } from "services/SvgFile";
import { Reg_Process } from "services/RegisterData";

import { ExclusaoIcmsPisCofinsSaidasFilial } from "services/FiscaltecAlgorithms/ExclusaoIcmsPisCofinsSaidasComFilial";

export default function Page_Process_Details(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ clients, setClients ]   = useState(GetDataPage('client'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('process'));

    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ idClient, setIdClient ]       = useState(InitialData('id_client'));
    const [ nameClient, setNameClient ]   = useState(InitialData('name_client'));
    const [ file, setFile ]               = useState([]);
    const [ status, setStatus ]           = useState(false);
    const [ start, setStart ]             = useState(InitialData('start'));
    const [ end, setEnd ]                 = useState(InitialData('end'));
    const [ note, setNote ]               = useState(InitialData('note'));
    const [ listFiles, setListFiles ]     = useState(InitialData('list_process'));
    const [ showProcess, setShowProcess ] = useState(InitialData('type_process'));
    const [ typeProcess, setTypeProcess ] = useState('Selecione uma opção');
    
    const listFunction = [
        {
            "value": "ExclusaoIcmsPisCofinsSaidasComFilial",
            "label": "Exclusão ICMS PIS COFINS saídas com filial",
            "function": ExclusaoIcmsPisCofinsSaidasFilial
        }
    ]

    function InitialData(type){
        if(idPage !=0 && idPage !='undefined'){
            if(dataPage.find(item => item.id == idPage)){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }else {
                if(type == 'name_client'){
                    return 'Selecione uma opção';
                }
                return '';
            }
        }
        if(type == 'list_process'){
            return [];
        }
        if(type == 'name_client'){
            return 'Selecione uma opção';
        }
        return '';
    }

    function ShowClients(){
        const newData = []
        clients.map((elem, index)=>{
            newData.push({ "value": elem.id, "label": elem.name });
        })
        return newData;
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile([]);
            setStatus(false);
        }
    }

    function AddSavedFile(dataFileContent){        
        if(idClient !=0){
            props.setLoading(true);
            let showNameFunction = listFunction.find(item => item.value == typeProcess);
            Reg_Process(userId, idPage, title, idClient, showNameFunction.label, dataFileContent, start, end, note, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            SetModalData('ReturnResponse', { "page": "erro", "text": "É necessário selecionar uma cliente..." });
            SetModalState('ReturnResponse', true);
        }
    }

    function ShowTypeProcess(event){
        event.preventDefault();
        let showFunction = listFunction.find(item => item.value == typeProcess);
        showFunction.function(file, AddSavedFile);
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'process');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('client', setClients);
        RegisterDataPage('process', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        if(idPage !=0){
            if(dataPage.find(item => item.id == idPage)){ }else {                
                SetModalData('ReturnResponse', { "page": "missingData", "text": "Cadastro removido ou corrompido" });
                SetModalState('ReturnResponse', true);
                ReturnPage();
            }
        }

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('client', setClients);
            UnRegisterDataPage('process', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setClients(GetDataPage('client'));
        setDataPage(GetDataPage('process'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setIdClient(InitialData('id_client'));
        setNameClient(InitialData('name_client'));
        setFile([]);
        setStatus(false);
        setStart(InitialData('start'));
        setEnd(InitialData('end'));
        setNote(InitialData('note'));
        setListFiles(InitialData('list_process'));
        setShowProcess(InitialData('type_process'));
    }, [dataPage, idPage]);

    return(
        <div className="Page_Process_Details">
            <form onSubmit={ ShowTypeProcess }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="show_icon">
                            <SvgSeta color={ colorIcon } className="icons" />
                        </div>
                        <div className="page_return" onClick={ ()=>{ ReturnPage() } }>
                            Voltar
                        </div>
                    </div>
                    <div className="highlight_title_page">
                        {
                            idPage == 0 ? "Cadastrar novo processo" : "Detalhes do processamento"
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do procesamento</div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            {
                                idPage != 0 ? 
                                <input type="text" value={ title } disabled={ true } /> :
                                <Select className="select_client" defaultOptions options={ ShowClients() } onChange={ (e)=> { setIdClient(e.value); setNameClient(e.label) } } value={ { "value": idClient, "label": nameClient } } placeholder="..." isDisabled={ idPage != 0 ? true : false } />
                            }
                            <span className="name_input">Cliente*</span>
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            {
                                idPage != 0 ?
                                <input type="text" value={ showProcess } disabled={ true } /> :
                                <select className="data_select" onChange={ (e)=>{ setTypeProcess(e.target.value); } } value={ typeProcess } required>
                                    <option value="" hidden>Selecione uma opção</option>
                                    {
                                        listFunction.map((elem, index)=>{
                                            return (
                                                <option value={ elem.value } label={ elem.label } key={ index }>{ elem.label }</option>
                                            )
                                        })
                                    }
                                </select>
                            }
                            <span className="name_input">Tipo de processo*</span>
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required disabled={ idPage != 0 ? true : false } />
                            <span className="name_input">Título*</span>
                        </div>
                        {/* 
                        <div className="div_input">
                            <input type="text" className="year" onChange={ (e)=>{ setYear(e.target.value) } } value={ year } maxLength="4" required disabled={ idPage != 0 ? true : false } />
                            <span className="name_input">Ano*</span>
                        </div> 
                        <div className="div_input">                            
                            {
                                idPage != 0 ?
                                <input type="text" className="month" value={ month } disabled={ true } /> :
                                <select className="" onChange={ (e)=>{ setMonth(e.target.value) } } value={ month } required>
                                    <option value="" hidden>Selecione uma opção</option>
                                    {
                                        listMonth.map((elem, index)=>{
                                            return (
                                                <option value={ elem } key={ index }>{ elem }</option>
                                            )
                                        })
                                    }
                                </select>
                            }
                            <span className="name_input">Mês*</span>
                        </div>
                        {
                            idPage == 0 ? null :
                            <>
                                <div className="div_input">
                                    <input type="date" className="date_document" value={ start } disabled={ true } />
                                    <span className="name_input">Data de inicial</span>
                                </div>
                                <div className="div_input">
                                    <input type="date" className="date_document" value={ end } disabled={ true } />
                                    <span className="name_input">Data de Término</span>
                                </div>
                            </>
                        }
                        */}
                        
                        {
                            idPage == 0 ?
                            <>
                                <div className="div_input">
                                    <input type="date" className="date_document" onChange={ (e)=>{ setStart(e.target.value) } } value={ start } />
                                    <span className="name_input">Data de inicial</span>
                                </div>
                                <div className="div_input">
                                    <input type="date" className="date_document" onChange={ (e)=>{ setEnd(e.target.value) } } value={ end } />
                                    <span className="name_input">Data de Término</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Arquivo adicionado" : "Adicionar arquivo*" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files); } } accept=".txt" multiple />
                                        </label>
                                    </div>
                                </span>
                            </>
                            : null
                        }
                    </div>
                    
                    {
                        idPage == 0 ? 
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } disabled={ idPage != 0 ? true : false } />
                            </div>
                        </div> :
                        note !='' && note !='<p><br></p>' ? 
                        <div className="list_input_data">
                            <div className="div_input space_div" dangerouslySetInnerHTML={ { __html: note ? note.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        </div>
                        : null
                    }

                    {
                        typeProcess != 'Selecione uma opção' && file.length > 0 && title !='' && idClient !=0 ?
                        <div className="list_input_data">
                            <div className="div_input div_add_img div_btn_process">
                                <button type="submit" className="new_file_add process">
                                    Processar arquivo
                                </button>
                            </div>
                        </div> : 
                        
                        idPage == 0 ? 
                        <div className="list_input_data">
                            <div className="div_input div_add_img div_btn_process">
                                <div type="submit" className="new_file_add process">
                                    É necessário preencher todos os campos com "*"
                                </div>
                            </div>
                        </div> : null
                    }
                </div>

                {
                    idPage == 0 || idPage == "undefined" ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Arquivos adicionados</div>
                        </div>
                        <div className="list_name_page">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Título</th>
                                        <th width="30" align="center" className="td_hide">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFiles.map((elem, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td align="center">{ index + 1 }</td>
                                                    <td>{ elem.title }</td>
                                                    <td align="center">
                                                        <a href={ elem.download } target="_blank" download={ elem.title } className="show_img_ cursor_pointer" data-tooltip-id="show_img" data-tooltip-content="Clique para iniciar o download do arquivo" data-tooltip-place="top">
                                                            <SvgDownload className="icons" color={ colorIcon } />
                                                        </a>
                                                        <Tooltip id="show_img" />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}