import React, { useState, useEffect } from "react";

import './EditProfile.css';

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_UserLogado } from "services/RegisterData";
import { SvgClose, SvgDelete } from "services/SvgFile";

export default function PopUP_EditProfile(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));

    const [ name, setName ]     = useState('');
    const [ email, setEmail ]   = useState('');
    const [ pass, setPass ]     = useState('');
    const [ file, setFile ]     = useState('');
    const [ status, setStatus ] = useState(false);

    function SaveData(){
        props.setLoading(true);
        Reg_UserLogado(userId, name, email, pass, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
        SetModalState('Profile', false);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setStatus(false);
        SetModalState('Profile', false);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        
        RegisterModalData('Profile', setModaldata);
        RegisterModalObserver('Profile', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setName(GetUserData('name'));
            setEmail(GetUserData('email'));
            setFile(GetUserData('file'));
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all popup_profile">
                    <div className="div_data type_div">
                        <div className="title">
                            Editar dados do perfil
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="space_width" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                    <span className="name_input">Nome</span>
                                </div>
                                <div className="div_input">
                                    <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" />
                                    <span className="name_input">Senha</span>
                                </div>
                            </div>
                            
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="space_width" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="140" />
                                    <span className="name_input">E-mail</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Foto adicionada" : "Adicionar foto" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                        </label>
                                    </div>
                                    {
                                        file !='' ?
                                        <>
                                            <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            <div className="show_img_ cursor_pointer" onClick={ ()=>{ OpenFile(status ? 'showImg_local' : 'showImg', status ? URL.createObjectURL(file) : "login/" + file) } } data-tooltip-id="show_img" data-tooltip-content="Clique para ampliar a foto" data-tooltip-place="top">
                                                <img alt="img_user" src={ status ? URL.createObjectURL(file) : "./assets/login/" + file } className="icons"/>
                                            </div>
                                        </>
                                        : null
                                    }
                                </span>
                            </div>

                            <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                Cadastrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
