import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { colorIcon, config } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_UserDash } from "services/RegisterData";
import { SvgDelete, SvgImg, SvgSave, SvgSeta } from "services/SvgFile";

export default function Page_UsersDash_Details(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));

    const [ name, setName ]     = useState(InitialData('name'));
    const [ email, setEmail ]   = useState(InitialData('email'));
    const [ file, setFile ]     = useState(InitialData('file'));
    const [ status, setStatus ] = useState(false);
    const [ pass, setPass ]     = useState('');
    const [ nivel, setNivel ]   = useState(InitialData('nivel'));
    const [ note, setNote ]     = useState(InitialData('note'));
    const [ page, setPage ]     = useState(InitialData('page'));

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.find(item => item.id == idPage)){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }else {
                if(type == 'page'){
                    return [
                        { "id": 0, "order": 0, "type": "hide_page", "page": "client", "name": "Clientes" },
                        { "id": 0, "order": 1, "type": "hide_page", "page": "process", "name": "Processos" },
                        { "id": 0, "order": 2, "type": "hide_page", "page": "historic", "name": "Alterações" }
                    ];
                }
                return '';
            }
        }
        if(type == 'page'){
            return [
                { "id": 0, "order": 0, "type": "hide_page", "page": "client", "name": "Clientes" },
                { "id": 0, "order": 1, "type": "hide_page", "page": "process", "name": "Processos" },
                { "id": 0, "order": 2, "type": "hide_page", "page": "historic", "name": "Alterações" }
            ];
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleData(type, index, value){
        const newData        = [...page];
        newData[index][type] = value;
        setPage(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_UserDash(userId, idPage, name, email, file, pass, nivel, note, page, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'access_dash');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('access_dash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        if(idPage !=0){
            if(dataPage.find(item => item.id == idPage)){ }else {                
                SetModalData('ReturnResponse', { "page": "missingData", "text": "Cadastro removido ou corrompido" });
                SetModalState('ReturnResponse', true);
                ReturnPage();
            }
        }

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('access_dash', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('access_dash'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setStatus(false);
        setPass('');
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_UsersDash_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="show_icon">
                            <SvgSeta color={ colorIcon } className="icons" />
                        </div>
                        <div className="page_return" onClick={ ()=>{ ReturnPage() } }>
                            Voltar
                        </div>
                    </div>
                    <div className="highlight_title_page">
                        Detalhes
                    </div>
                    <button className="list_opt_alt_page">
                        <div className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do usuário</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="16" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="60" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        
                        {
                            idPage == 0 ? null :                            
                            <div className="div_input">                            
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" value={ pass } />
                                <span className="name_input">Senha</span>
                            </div>  
                        }
                                             
                        {
                            idPage == userId ? null :
                            <div className="div_input">
                                <select className="data_select" onChange={ (e)=>{ setNivel(e.target.value) } } value={ nivel }>
                                    <option value="0">Usuário</option>
                                    <option value="1">Administrador</option>
                                </select>
                                <span className="name_input">Acesso</span>
                            </div>
                        }                        
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    <div className="show_img_ cursor_pointer" onClick={ ()=>{ OpenFile(status ? 'showImg_local' : 'showImg', status ? URL.createObjectURL(file) : "login/" + file) } } data-tooltip-id="show_img" data-tooltip-content="Clique para ampliar a foto" data-tooltip-place="top">
                                        <img alt="img_user" src={ status ? URL.createObjectURL(file) : "./assets/login/" + file } className="icons"/>
                                    </div>
                                    <Tooltip id="show_img" />
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>
                </div>

                {
                    nivel == 1 ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Acesso as páginas</div>
                        </div>

                        <div className="list_data_mobile">
                            {
                                page.map((elem, index)=>{
                                    return(
                                        <div className="list_input_data data_mobile" key={ index }>
                                            <div className="div_input div_name_page">
                                                <input type="text" className="nem_page" value={ elem.name } readOnly />
                                                <span className="name_input">Página</span>
                                            </div>
                                            <div className="div_input space_div">
                                                <select className="type_access" onChange={ (e)=>{ HandleData('type', index, e.target.value) } } value={ elem.type }>
                                                    <option value="hide_page">Sem acesso</option>
                                                    <option value="show_only">Somente visualização</option>
                                                    {
                                                        elem.page == "historic" ? null : <option value="edit">Pode editar</option>
                                                    }
                                                </select>
                                                <span className="name_input">Tipo de acesso</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="list_name_page">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Página</th>
                                        <th className="td_hide" width="200">Tipo de acesso</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        page.map((elem, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td>{ index + 1 }</td>
                                                    <td>{ elem.name }</td>
                                                    <td>
                                                        <select className="" onChange={ (e)=>{ HandleData('type', index, e.target.value) } } value={ elem.type }>
                                                            <option value="hide_page">Sem acesso</option>
                                                            <option value="show_only">Somente visualização</option>
                                                            {
                                                                elem.page == "historic" ? null : <option value="edit">Pode editar</option>
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}