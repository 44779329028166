import { useState, useEffect } from "react";

import './Home.css';

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Home(props){
    
    const [ user, setUser ]       = useState(GetDataPage('access_dash'));
    const [ client, setClient ]   = useState(GetDataPage('client'));
    const [ process, setProcess ] = useState(GetDataPage('process'));

    useEffect(()=>{
        RegisterDataPage('access_dash', setUser);
        RegisterDataPage('client', setClient);
        RegisterDataPage('process', setProcess);

        return ()=>{
            UnRegisterDataPage('access_dash', setUser);
            UnRegisterDataPage('client', setClient);
            UnRegisterDataPage('process', setProcess);
        };
    }, []);
    
    return(
        <div className="Page_Home">
            {
                props.userAccess == 1 ? 
                <>
                    <div className="div_data" onClick={ ()=>{ props.CkickPage('access_dash') } }>
                        <div className="qtd">{ user.length }</div>
                        <div className="title">Usuários</div>
                    </div>
                    
                    <div className="div_data" onClick={ ()=>{ props.CkickPage('client') } }>
                        <div className="qtd">{ client.length }</div>
                        <div className="title">Clientes</div>
                    </div>
                    
                    <div className="div_data" onClick={ ()=>{ props.CkickPage('process') } }>
                        <div className="qtd">{ process.length }</div>
                        <div className="title">Processos</div>
                    </div>
                </>
                :
                <div className="">Selecione uma opção do menu ao lado para iniciar</div>
            }
        </div>
    )
}