import React, { useState, useEffect } from "react";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import Iframe from 'react-iframe';

import './ShowFile.css';

import { SvgClose } from "services/SvgFile";
import { colorIcon } from "fixedData";

export default function PopUP_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function ClosePopUp(){
        SetModalState('ShowFile', false);
    }

    function OpenFile(){
        switch (modalData.type) {
            case 'showImg':
                return <img alt="img" src={ "./assets/" + modalData.file } className="show_img" />;

            case 'showImg_local':
                return <img alt="img" src={ modalData.file } className="show_img" />;

            case 'showPdf':
                return <Iframe url={ "./assets/" + modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'showPdf_local':
                return <Iframe url={ modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'Vimeo':
                let linkVimeo = modalData.file.split('/');
                return <Iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[3] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'Youtube':
                let linkYoutube = modalData.file.split('=');
                return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'Drive':
                let linkDrive = modalData.file.split('/');
                return <Iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" }frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;
        }
    }

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all show_file" style={ { backgroundColor: colorIcon } }>
                    <div className="div_data type_div">
                        <div className="title"></div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#ffffff" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            {
                                OpenFile()
                            }
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
