import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { colorIcon, config } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { SvgDelete, SvgDownload, SvgEye, SvgHistoric, SvgSave, SvgSeta } from "services/SvgFile";
import { phoneMask } from "services/Mask";
import { Reg_Client } from "services/RegisterData";

export default function Page_Client_Details(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('client'));

    const [ name, setName ]                   = useState(InitialData('name'));
    const [ corporateName, setCorporateName ] = useState(InitialData('corporate_name'));
    const [ logo, setLogo ]                   = useState(InitialData('logo_client'));
    const [ status, setStatus ]               = useState(false);
    const [ cnpj, setCnpj ]                   = useState(InitialData('cnpj'));
    const [ state, setState ]                 = useState(InitialData('state'));
    const [ note, setNote ]                   = useState(InitialData('note'));
    const [ contact, setContact ]             = useState(InitialData('contact'));
    const [ contactOff, setContactOff ]       = useState(ShowOff());
    const [ taxRegime, setTaxRegime ]         = useState(InitialData('tax_regime'));
    const [ process, setProcess ]             = useState(ShowProcess());

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.find(item => item.id == idPage)){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }else {
                if(type == 'contact' || type == 'tax_regime'){
                    return [];
                }
                return '';
            }
        }
        if(type == 'contact' || type == 'tax_regime'){
            return [];
        }
        return '';
    }

    function ShowProcess(){
        const newData  = [];
        const listData = GetDataPage('process').filter(item => item.id_client == idPage);
        listData.map((elem, index)=>{
            if(elem.status == 1){
                newData.push(elem)                    
            }
        })
        return newData;
    }

    function ShowOff(){
        if(dataPage.find(item => item.id == idPage)){
            const newData  = [];
            const listData = dataPage.find(item => item.id == idPage);
            listData.contact.map((elem, index_1)=>{
                if(elem.status == 0){
                    newData.push({ "id": elem.id, "type": "client", "title": elem.name, "removed_name": elem.removed_name, "removed_date": elem.removed_date })                    
                }
            })
            return newData;
        }
        return [];
    }

    function AddFile(value){
        if(value){
            setLogo(value);
            setStatus(true);
        }else {
            setLogo('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleData(type, index, value){  
        const newData = [...contact];
        if(type == 'file'){
            if(value){
                newData[index]['file']       = value;
                newData[index]['statusFile'] = true;
            }else {
                newData[index]['file']       = "";
                newData[index]['statusFile'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setContact(newData);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...contact];
            newData.splice(index, 1);
            setContact(newData);
        }else {                
            SetModalData('Confirmation', { "origin": "client", "type" : "alt_status_contact", "status": 0, "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Client(userId, idPage, name, corporateName, logo, cnpj, state, note, contact, taxRegime, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'client');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('client', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        if(idPage !=0){
            if(dataPage.find(item => item.id == idPage)){ }else {                
                SetModalData('ReturnResponse', { "page": "missingData", "text": "Cadastro removido ou corrompido" });
                SetModalState('ReturnResponse', true);
                ReturnPage();
            }
        }

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('access_dash', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('client'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setCorporateName(InitialData('corporate_name'));
        setLogo(InitialData('logo_client'));
        setStatus(false);
        setCnpj(InitialData('cnpj'));
        setState(InitialData('state'));
        setNote(InitialData('note'));
        setContact(InitialData('contact'));
        setContactOff(ShowOff());
        setTaxRegime(InitialData('tax_regime'));
        setProcess(ShowProcess());
    }, [dataPage, idPage]);

    return(
        <div className="Page_Client_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="show_icon">
                            <SvgSeta color={ colorIcon } className="icons" />
                        </div>
                        <div className="page_return" onClick={ ()=>{ ReturnPage() } }>
                            Voltar
                        </div>
                    </div>
                    <div className="highlight_title_page">
                        Detalhes do cliente
                    </div>
                    {
                        props.typeAccess == "edit" ? 
                        <button className="list_opt_alt_page">
                            <div className="div_add_new_data">
                                <div className="new_data_add">
                                    <SvgSave color="#ffffff" className="icons" />
                                </div>
                                <div className="name_btn_add">Salvar</div>
                            </div>
                        </button>
                        : null
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados da empresa</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" readOnly={ props.typeAccess == "edit" ? false : true } required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setCorporateName(e.target.value) } } value={ corporateName } maxLength="18" readOnly={ props.typeAccess == "edit" ? false : true } required />
                            <span className="name_input">Razão Social</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="cnpj" onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpj } maxLength="18" readOnly={ props.typeAccess == "edit" ? false : true } />
                            <span className="name_input">CNPJ</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="state" onChange={ (e)=>{ setState(e.target.value) } } value={ state } maxLength="2" readOnly={ props.typeAccess == "edit" ? false : true } />
                            <span className="name_input">Estado</span>
                        </div>
                        <span className="div_input div_add_img">
                            {
                                props.typeAccess == "edit" ?
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Logotipo adicionado" : "Adicionar logotipo" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                        </label>
                                    </div>
                                : null
                            }
                            {
                                logo !='' ?
                                <>
                                    {
                                        props.typeAccess == "edit" ?
                                        <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div>
                                        : null
                                    }
                                    <div className="show_img_ cursor_pointer" onClick={ ()=>{ OpenFile(status ? 'showImg_local' : 'showImg', status ? URL.createObjectURL(logo) : "client/" + logo) } } data-tooltip-id="show_img" data-tooltip-content="Clique para ampliar o logotipo" data-tooltip-place="top">
                                        <img alt="logo do cliente" src={ status ? URL.createObjectURL(logo) : "./assets/client/" + logo } className="icons"/>
                                    </div>
                                    <Tooltip id="show_img" />
                                </>
                                : null
                            }
                        </span>
                    </div>
                    
                    {
                        props.typeAccess == "edit" ?
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                            </div>
                        </div> :
                        note !='' && note !='<p><br></p>' ? 
                        <div className="list_input_data">
                            <div className="space_div" dangerouslySetInnerHTML={ { __html: note ? note.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        </div> : null
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Contatos</div>

                        <div className="list_opt">
                            {
                                contactOff.length > 0 ? 
                                <div className="" onClick={ ()=>{ SetModalData('Historic', { "listData": contactOff, "type": "alt_status_contact" } ); SetModalState('Historic', true); }}>
                                    <SvgHistoric className="icons" color={ colorIcon }/>
                                </div> : null
                            }
                            {
                                props.typeAccess == "edit" ? 
                                <div className="new_file_add" onClick={ ()=>{ setContact([...contact, { "id": 0, "status": 1, "name": "", "email": "", "phone": "", "office": "", "file": "", "statusFile": false, "note": "" }]) } }>
                                    Adicionar novo contato
                                </div>
                                : null
                            }
                        </div>         
                    </div>
                    {
                        contact.length > 0 ? 
                        contact.map((elem, index)=>{
                            if(elem.status == 1){
                                return(
                                    <div className="list_name_page" key={ index }>
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="" onChange={ (e)=>{ HandleData('name', index, e.target.value) } } value={ elem.name } maxLength="80" readOnly={ props.typeAccess == "edit" ? false : true } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <div className="div_input space_div">
                                                <input type="text" className="" onChange={ (e)=>{ HandleData('email', index, e.target.value) } } value={ elem.email } maxLength="40" readOnly={ props.typeAccess == "edit" ? false : true } />
                                                <span className="name_input">E-mail</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="text" className="phone" onChange={ (e)=>{ HandleData('phone', index, e.target.value) } } value={ phoneMask(elem.phone) } maxLength="50" readOnly={ props.typeAccess == "edit" ? false : true } />
                                                <span className="name_input">Contato</span>
                                            </div>
                                            {
                                                props.typeAccess == "edit" ? 
                                                <div className="div_input div_delete" onClick={ ()=>{ DeleteData(elem.id, index, elem.name) } }>
                                                    <SvgDelete className="icons" color="#f00000" />
                                                </div>
                                                : null
                                            }
                                        </div>

                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="" onChange={ (e)=>{ HandleData('office', index, e.target.value) } } value={ elem.office } maxLength="80" readOnly={ props.typeAccess == "edit" ? false : true } />
                                                <span className="name_input">Cargo</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                {
                                                    props.typeAccess == "edit" ?
                                                    <div className={ elem.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            { elem.statusFile == true ? "Foto adicionada" : "Adicionar foto" }
                                                            <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    : null
                                                }
                                                {
                                                    elem.file !='' ?
                                                    <>
                                                        {
                                                            props.typeAccess == "edit" ?
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleData('file', index, ''); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            : null
                                                        }
                                                        <div className="show_img_ cursor_pointer" onClick={ ()=>{ OpenFile(elem.statusFile ? 'showImg_local' : 'showImg', elem.statusFile ? URL.createObjectURL(elem.file) : "client/" + elem.file) } } data-tooltip-id="show_img" data-tooltip-content="Clique para ampliar a foto" data-tooltip-place="top">
                                                            <img alt="img contact" src={ elem.statusFile ? URL.createObjectURL(elem.file) : "./assets/client/" + elem.file } className="icons"/>
                                                        </div>
                                                        <Tooltip id="show_img" />
                                                    </>
                                                    : null
                                                }
                                            </span>
                                        </div>

                                        {
                                            props.typeAccess == "edit" ?
                                            <div className="list_input_data">
                                                <div className="div_input space_div">
                                                    <JoditEditor config={ config } value={ elem.note ? elem.note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('note', index, newContent) } />
                                                </div>
                                            </div> :
                                            elem.note !='' && elem.note !='<p><br></p>' ? 
                                            <div className="list_input_data">
                                                <div className="space_div" dangerouslySetInnerHTML={ { __html: elem.note ? elem.note.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </div> : null
                                        }
                                    </div>
                                )
                            }
                        })
                        : 
                        <div className="no_data">Nenhum contato adicionado...</div>
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Regime tributário</div>
                    </div>
                    <div className="">
                        {
                            taxRegime.length > 0 ? 
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Tipo</th>
                                        <th className="td_hide" width="40" align="center">Ano</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {                                    
                                        taxRegime.map((elem, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td align="center">{ index + 1 }</td>
                                                    <td>{ elem.type }</td>
                                                    <td className="td_hide" align="center">{ elem.year }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            : 
                            <div className="no_data">Nenhum regime tributário adicionado...</div>
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista de processos</div>
                    </div>
                    <div className="">
                        {
                            process.length > 0 ? 
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Título</th>
                                        <th className="td_hide" width="40" align="center">Ano</th>
                                        <th className="td_hide" width="40" align="center">Mês</th>
                                        {
                                            props.accessProcess != 'hide_page' ?
                                            <th width="20" align="center">#</th>
                                            : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {                                    
                                        process.map((elem, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td align="center">{ index + 1 }</td>
                                                    <td>{ elem.title }</td>
                                                    <td className="td_hide" align="center">{ elem.year }</td>
                                                    <td className="td_hide" align="center">{ elem.month }</td>
                                                    {
                                                        props.accessProcess != 'hide_page' ?
                                                        <td>
                                                            <div className="show_img_ cursor_pointer" data-tooltip-id="show_img" data-tooltip-content="Clique aqui ir para o documento" data-tooltip-place="top" onClick={ ()=>{ SetListPag('currentPage', 'process_details'); SetListPag('currentPageId', elem.id); } }>
                                                                <SvgEye className="icons" color={ colorIcon } />
                                                            </div>
                                                            <Tooltip id="show_img" />
                                                        </td>
                                                        : null
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            : 
                            <div className="no_data">Nenhum processo adicionado...</div>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}