function escreverNotas(fragment){
    let output = '\n';
    for (let a in fragment) {
        output += fragment[a].c010 + '\n';
        for (let b in fragment[a]) {
            if (fragment[a][b].c100) {                
                output += fragment[a][b].c100 + '\n';
            }
            for (let c in fragment[a][b].c170) {
                output += fragment[a][b].c170[c] + '\n';
            }
        }
    }
    return output;
}

export function ExclusaoIcmsPisCofinsSaidasFilial(documentFile, AddSavedFile){
    let count = 0;    
    let localSaveFileContent = [];
    for (let index = 0; index < documentFile.length; index++) {
        localSaveFileContent[index] = { "data_file": documentFile[index], "text": "" };

        let listaInicialArquivo = [];
        let objeto = {};
        let c010;
        let c100;
        let nfe;
        
        const file = documentFile[index];
        const reader = new FileReader();

        reader.onload = function(event) {
            const fileContent = event.target.result;
            const lines = fileContent.split("\r\n");

            for (let linContri of lines) {
                let line = linContri.split('|');
                if (line[0] === "") {
                    // Armazenando a estrutura inicial do arquivo
                    listaInicialArquivo.push(linContri);
                    if (line[1] === "C010") {
                        c010 = linContri
                        if (!objeto[c010]) {
                            objeto[c010] = { 'c010': linContri }
                        }
                    } else if (line[1] === "C100") {
                        c100 = linContri
                        nfe = line[9];
                        if (!objeto[c010][nfe]) {
                            objeto[c010][nfe] = { ['c100']: linContri, 'c170': [] }
                        }
                    } else if (line[1] === "C170") {
                        if (objeto[c010][nfe]['c100']) {
                            if (Number(linContri.split("|")[11]) > 5000 && Number(line[11]) !== 5201 && Number(line[11]) !== 5202 && Number(line[11]) !== 5410 && Number(line[11]) !== 6201 && Number(line[11]) !== 6202 && Number(line[11]) !== 6410) {
                                let x = Number(line[26].replace(',', '.')) - (Number(line[7].replace(',', '.')) - Number(line[8].replace(',', '.')));
                                if (x > 0.10) {
                                    if (Number(line[15].replace(',', '.')) > 0 && Number(line[30].replace(',', '.')) > 0) {
                                        let novoVlBcPis = (Number(line[26].replace(',', '.')) - Number(line[15].replace(',', '.'))).toFixed(2);
                                        let novoVlBcCofins = (Number(line[32].replace(',', '.')) - Number(line[15].replace(',', '.'))).toFixed(2);
                                        let novoVlPis = Number(novoVlBcPis * 0.0165).toFixed(2);
                                        let novoVlCofins = Number(novoVlBcCofins * 0.076).toFixed(2);
                                        let novoVlPisC100 = Number(Number(objeto[c010][nfe]["c100"].split("|")[26].replace(',', '.')) - Number(line[30].replace(',', '.')) + Number(novoVlPis)).toFixed(2);
                                        let novoVlCofinsC100 = Number(Number(objeto[c010][nfe]["c100"].split("|")[27].replace(',', '.')) - Number(line[36].replace(',', '.')) + Number(novoVlCofins)).toFixed(2);
                                        objeto[c010][nfe]["c170"].push(
                                            "|" + line[1] + "|" + line[2] + "|" + line[3] + "|" + line[4] + "|" + line[5] + "|" + line[6] + "|" + line[7] + "|" + line[8] + "|" + line[9] + "|" + line[10] + "|"
                                            + line[11] + "|" + line[12] + "|" + line[13] + "|" + line[14] + "|" + line[15] + "|" + line[16] + "|" + line[17] + "|" + line[18] + "|" + line[19] + "|" + line[20] + "|"
                                            + line[21] + "|" + line[22] + "|" + line[23] + "|" + line[24] + "|" + line[25] + "|" + String(novoVlBcPis).replace('.', ',') + "|" + line[27] + "|" + line[28] + "|" + line[29] + "|" + String(novoVlPis).replace('.', ',') + "|"
                                            + line[31] + "|" + String(novoVlBcCofins).replace('.', ',') + "|" + line[33] + "|" + line[34] + "|" + line[35] + "|" + String(novoVlCofins).replace('.', ',') + "|" + line[37] + "|"
                                        );
                                        objeto[c010][nfe]["c100"] = "|" + objeto[c010][nfe]["c100"].split("|")[1] + "|" + objeto[c010][nfe]["c100"].split("|")[2] + "|" + objeto[c010][nfe]["c100"].split("|")[3] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[4] + "|" + objeto[c010][nfe]["c100"].split("|")[5] + "|" + objeto[c010][nfe]["c100"].split("|")[6] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[7] + "|" + objeto[c010][nfe]["c100"].split("|")[8] + "|" + objeto[c010][nfe]["c100"].split("|")[9] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[10] + "|" + objeto[c010][nfe]["c100"].split("|")[11] + "|" + objeto[c010][nfe]["c100"].split("|")[12] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[13] + "|" + objeto[c010][nfe]["c100"].split("|")[14] + "|" + objeto[c010][nfe]["c100"].split("|")[15] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[16] + "|" + objeto[c010][nfe]["c100"].split("|")[17] + "|" + objeto[c010][nfe]["c100"].split("|")[18] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[19] + "|" + objeto[c010][nfe]["c100"].split("|")[20] + "|" + objeto[c010][nfe]["c100"].split("|")[21] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[22] + "|" + objeto[c010][nfe]["c100"].split("|")[23] + "|" + objeto[c010][nfe]["c100"].split("|")[24] + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[25] + "|" + String(novoVlPisC100).replace('.', ',') + "|" + String(novoVlCofinsC100).replace('.', ',') + "|" +
                                            objeto[c010][nfe]["c100"].split("|")[28] + "|" + objeto[c010][nfe]["c100"].split("|")[29] + "|"
                                    } else {
                                        if (objeto[c010][nfe]["c100"]) {
                                            objeto[c010][nfe]["c170"].push(linContri);
                                        }
                                    }
                                } else {
                                    if (objeto[c010][nfe]["c100"]) {
                                        objeto[c010][nfe]["c170"].push(linContri);
                                    }
                                }
                            } else {
                                if (objeto[c010][nfe]["c100"]) {
                                    objeto[c010][nfe]["c170"].push(linContri);
                                }
                            }
                        }
                    } else if (line[1] === "C110") {
                        if (objeto[c010][nfe]["c100"]) {
                            objeto[c010][nfe]["c170"].push(linContri);
                        }
                    } else if (line[1] === "C120") {
                        if (objeto[c010][nfe]["c100"]) {
                            objeto[c010][nfe]["c170"].push(linContri);
                        }
                    } else if (line[1] === 'C990') {
                        localSaveFileContent[index]['text'] += escreverNotas(objeto);
                        localSaveFileContent[index]['text'] += linContri + '\n';
                    } else {                  
                        localSaveFileContent[index]['text'] += linContri + '\n';
                    } // pode existir C120 e C175
                }
            }
            
            count++;
            if(count == documentFile.length){
                AddSavedFile(localSaveFileContent);
            }
        };
        reader.readAsText(file);
    }
}