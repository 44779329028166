import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData, UnRegisterUserPage } from 'interface/Users';
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from 'interface/Page';

import { SvgClient, SvgDashboard, SvgDashboardExit, SvgHistoric, SvgMenuHamburguer, SvgMenuPoint, SvgNoteFiscal, SvgUser, SvgUserDash } from 'services/SvgFile';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePageId, typeCookiePassw } from 'fixedData';

import PopUP_ShowFile from 'components/PopUp/ShowFile';
import PopUP_Historic from 'components/PopUp/Historic';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';

import Page_Home from 'pages/Home';

import Page_Client from 'pages/Client';
import Page_Client_Details from 'pages/Client/Details';

import Page_Process from 'pages/Process';
import Page_Process_Details from 'pages/Process/Details';

import Page_Historic from 'pages/Historic';

import Page_UsersDash from 'pages/UsersDash';
import Page_UsersDash_Details from 'pages/UsersDash/Details';

export default function Contents(props){
    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ status, setStatus ] = useState(false);  

    function CkickPage(value){
        SetListPag('currentPage', value);
        document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' });

        if(document.body.clientWidth <= 1050){
            setStatus(false);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(false);
            }
        });
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
        cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
    }

    function CurrentPage(){
        switch (currentPage) {
            case "access_dash":
                return <Page_UsersDash setLoading={ props.setLoading } />;
            case "access_dash_details":
                return <Page_UsersDash_Details setLoading={ props.setLoading } />;

            case "client":
                return <Page_Client setLoading={ props.setLoading } typeAccess={ userAccess == 1 ? "edit" : userPage.find(item => item.page == 'client').type } accessProcess={ userAccess == 1 ? "edit" : userPage.find(item => item.page == 'process').type } />;
            case "client_details":
                return <Page_Client_Details setLoading={ props.setLoading } typeAccess={ userAccess == 1 ? "edit" : userPage.find(item => item.page == 'client').type } accessProcess={ userAccess == 1 ? "edit" : userPage.find(item => item.page == 'process').type } />;

            case "process":
                return <Page_Process setLoading={ props.setLoading } typeAccess={ userAccess == 1 ? "edit" : userPage.find(item => item.page == 'process').type } />;
            case "process_details":
                return <Page_Process_Details setLoading={ props.setLoading } typeAccess={ userAccess == 1 ? "edit" : userPage.find(item => item.page == 'process').type } />;

            case "historic":
                return <Page_Historic setLoading={ props.setLoading } typeAccess={ userAccess == 1 ? "edit" : userPage.find(item => item.page == 'historic').type } />;
        
            default:
                return <Page_Home setLoading={ props.setLoading } CkickPage={ CkickPage } userAccess={ userAccess } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);

        if(document.body.clientWidth <= 1050){
            setStatus(false);
        }
        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(false);
            }
        });

        return ()=>{
            UnRegisterListPag('currentPage', setCurrentPage);
            
            UnRegisterUserPage('file', setUserFile);
            UnRegisterUserPage('name', setUserName);
            UnRegisterUserPage('access', setUserAccess);
            UnRegisterUserPage('page', setUserPage);
        };
    }, []);
    
    return(
        <div className="Contents">
            <div className={ status ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">

                    <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                        <div className={ status ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                            <div className="">
                                <img alt="logotipo" src="./assets/logo_1.png" className={ status ? "logotipo2" : "logotipo" } />
                            </div>
                            <div className={ status ? "icon_menu icon_menu_active" : "icon_menu" }>
                                <SvgMenuHamburguer color="#ffffff" className="icons" />
                            </div>
                        </div>                    
                    </div>
                    
                    <div className={ status ? "show_data_top" : "show_data_top show_data_top_close" }>
                        <div className="opt_menu_dash">
                            
                            <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboard color="#ffffff" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Dashboard</div>
                                </div>
                            </div>

                            {
                                userAccess == 1 ?
                                <>                                    
                                    <div className={ currentPage == "client" || currentPage == "client_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("client"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgClient color="#ffffff" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Clientes</div>
                                        </div>
                                    </div>  

                                    <div className={ currentPage == "process" || currentPage == "process_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("process"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgNoteFiscal color="#ffffff" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Processos</div>
                                        </div>
                                    </div> 

                                    <div className={ currentPage == "historic" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("historic"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgHistoric color="#ffffff" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Alterações</div>
                                        </div>
                                    </div>  

                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgUserDash color="#ffffff" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Usuários</div>
                                        </div>
                                    </div>
                                </> :
                                userPage.map((elem, index)=>{
                                    if(elem.type != "hide_page"){
                                        let iconMenu   = '';
                                        switch (elem.page) {
                                            case 'client':
                                                    iconMenu = <SvgClient color="#ffffff" className="icons_dash" />;
                                                    if(currentPage == 'client' && currentPage == 'client_details'){

                                                    }
                                                break;
                                            case 'process':
                                                    iconMenu = <SvgNoteFiscal color="#ffffff" className="icons_dash" />;
                                                break;
                                            case 'historic':
                                                    iconMenu = <SvgHistoric color="#ffffff" className="icons_dash" />;
                                                break;
                                        }
                                                
                                        let activeMenu = '';
                                        if(elem.page == 'client' && currentPage == 'client' || elem.page == 'client' && currentPage == 'client_details'){
                                            status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                        }else if(elem.page == 'process' && currentPage == 'process' || elem.page == 'process' && currentPage == 'process_details'){
                                            status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                        }else if(elem.page == 'historic' && currentPage == 'historic'){
                                            status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                        }else{
                                            status == true ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                        }
                                                
                                        return(
                                            <div className={ activeMenu } onClick={ ()=>{ CkickPage(elem.page); } } key={ index }>
                                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                                    <div className="div_icons">
                                                        { iconMenu }
                                                    </div>
                                                    <div className={ status ? "name_page menu_close" : "name_page" }>
                                                        { elem.name }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }

                            <div className={ status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#ffffff" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Sair</div>
                                </div>
                            </div>
                        </div>
                        <div className="div_profile">
                            <div className={ status ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ SetModalState('Profile', true); } }>
                                <div className="div_img">
                                    {
                                        userFile !='' ?
                                        <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                        <SvgUser color="#FFFFFF" className="show_img_profile" />
                                    }
                                </div>
                                <div className={ status ? "name_user menu_close" : "name_user" }>
                                    { userName.split(' ')[0] }
                                </div>
                                <div className={ status ? "open_profile menu_close" : "open_profile" }>
                                    <SvgMenuPoint color="#ffffff" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>

            <PopUP_EditProfile setLoading={ props.setLoading } />
            <PopUP_Historic setLoading={ props.setLoading } />
            <PopUP_ShowFile setLoading={ props.setLoading } />
            <PopUP_Confirmation setLoading={ props.setLoading } />

            <PopUP_ReturnResponse setLoading={ props.setLoading } />
        </div>
    )
}