import { useState, useEffect } from "react";

import './Historic.css';

import { SvgSearch } from "services/SvgFile";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Historic(props){
    
    const [ search, setSearch ]     = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('historic'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('historic').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(GetDataPage('historic'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setShowData);

        return ()=>{
            UnRegisterDataPage('access_dash', setShowData);
        };
    }, []);

    return(
        <div className="Page_Historic">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="highlight_title_page">
                    Histórico de alterações
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th align="center" width="20">#</th>
                            <th align="left" width="80">Menu</th>
                            <th align="left">Descrição</th>
                            <th align="left" width="50">Data</th>
                            <th align="left" width="30">Hora</th>
                            <th align="left" width="110">Quem Alterou</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name_page }</td>
                                        <td className="td_hide" dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                        <td>{ elem.register_date }</td>
                                        <td>{ elem.register_hour }</td>
                                        <td>{ elem.register_name }</td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 6 }>Nenhuma alteração registrada...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}