import { useState, useEffect } from "react";

import './Client.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { SvgAddNewData, SvgDelete, SvgEdit, SvgEye, SvgHistoric, SvgSearch } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Page_Client(props){

    const [ search, setSearch ]         = useState('');
    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ showData, setShowData ]     = useState(GetDataPage('client'));
    const [ listClient, setListClient ] = useState(InitialData('all'));
    const [ listOff, setListOff ]       = useState(InitialData('deleted'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(listClient.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = listClient.slice(startItens, endItens);

    function InitialData(type){
        if(type == "all"){
            const newData = GetDataPage('client').filter(item => item.status == 1);
            return newData;
        }
        if(type == "deleted"){
            const newData = [];
            GetDataPage('client').map((elem, index)=>{
                if(elem.status == 0){
                    newData.push({ "id": elem.id, "type": "client", "title": elem.name, "removed_name": elem.removed_name, "removed_date": elem.removed_date })
                }
            });
            return newData;
        }
    }
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('client').forEach(item =>{
                if(item.status !=2){
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.code.indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.corporate_name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.note.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.state.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListClient(duplicate);
        }else {
            setListClient(InitialData('all'));
        }
        setSearch(value);
    }

    function AltAccess(id, value){
        SetModalData('Confirmation', { "origin": "client", "type" : "alt_status_data", "status": 0, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'client_details');
        SetListPag('currentPageId', idPage);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('client', setShowData);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('client', setShowData);
        };
    }, []);

    useEffect(()=>{
        setListClient(InitialData('all'));
        setListOff(InitialData('deleted'));
    }, [showData]);

    return(
        <div className="Page_Client">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="list_opt_alt_page">
                    {
                        listOff.length > 0 ? 
                        <div className="" onClick={ ()=>{ SetModalData('Historic', { "listData": listOff, "type": "alt_status_data" } ); SetModalState('Historic', true); }}>
                            <SvgHistoric className="icons" color={ colorIcon }/>
                        </div> : null
                    }
                    {
                        props.typeAccess == "edit" ? 
                        <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                            <div className="new_data_add">
                                <SvgAddNewData className="icons" color="#ffffff" />
                            </div>
                            <div className="name_btn_add">cliente</div>
                        </div>
                        : null
                    }
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="60">Código</th>
                            <th>Nome</th>
                            <th className="td_hide_2">Razão Social</th>
                            <th className="td_hide_1" width="134">Cnpj</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.code }</td>
                                        <td>{ elem.name }</td>
                                        <td className="td_hide_2">{ elem.corporate_name }</td>
                                        <td className="td_hide_1">{ elem.cnpj }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                {
                                                    props.typeAccess == "edit" ? 
                                                    <>
                                                        <div onClick={ ()=>{ AltAccess(elem.id, elem.name) } } data-tooltip-id={ "id_delete_" + elem.id } data-tooltip-content="Deletar cliente" data-tooltip-place="top">
                                                            <SvgDelete color="#F00000" className="icons"/>
                                                        </div>
                                                        <Tooltip id={ "id_delete_" + elem.id } />
                                                    </>
                                                    : null
                                                }
                                                <div onClick={ ()=>{ PageClick(elem.id) } } data-tooltip-id={ "id_edit_" + elem.id } data-tooltip-content={ props.typeAccess == "edit" ? "Editar cliente" : "Visualizar cliente" } data-tooltip-place="top">
                                                    {
                                                        props.typeAccess == "edit" ? 
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                        :
                                                        <SvgEye color="#324d6b" className="icons"/>
                                                    }
                                                </div>
                                                <Tooltip id={ "id_edit_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 6 }>Nenhum cliente registrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    listClient.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}