import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'fixedData';

// Alt Status data
export function Reg_AltStatus(userId, type, id, origin, status, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('status', status);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/' + origin + '.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        SetListDataSingle('historic', response.data.historic);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data    = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/inc/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            case "Email não enviado":
                    CallbackError_Send();
                break;
            case "E-mail não encontrado":
                    CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                    CallbackError();
                break;
        }

    }).catch((error)=>{

        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/inc/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        if(response.data == "conectado"){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, file, pass, nivel, note, page, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    page.forEach((elem, index) => {
        data.append('page_id[]', elem.id);
        data.append('page_order[]', elem.order);
        data.append('page_type[]', elem.type);
        data.append('page_page[]', elem.page);
        data.append('page_name[]', elem.name);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/access_dash.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_UserLogado(userId, name, email, pass, file, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_user');

    data.append('id', userId);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/access_dash.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('access_dash', response.data.access_dash);        
        
        const newData = response.data.access_dash.find(item => item.id == userId);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        // current date
        const day = new Date();

        // add 3 day
        const add3Days = new Date();
        add3Days.setDate(day.getDate() + 2);
        cookies.set(typeCookieEmail, email, { path: '/', expires: add3Days }, cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit client
export function Reg_Client(userId, idPage, name, corporateName, logo, cnpj, state, note, contact, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'client');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('corporate_name', corporateName);
    data.append('logo', logo);
    data.append('cnpj', cnpj);
    data.append('state', state);
    data.append('note', note);

    // contact
    contact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_phone[]', elem.phone);
        data.append('contact_office[]', elem.office);
        data.append('contact_file_' + index, elem.file);
        data.append('contact_note[]', elem.note);
    });
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/client.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('client', response.data.client);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit process
export function Reg_Process(userId, idPage, title, idClient, typeProcess, savedFileContent, start, end, note, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'process');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('id_client', idClient);
    data.append('type_process', typeProcess);
    data.append('start', start);
    data.append('end', end);
    data.append('note', note);

    savedFileContent.forEach((elem, index) => {
        data.append('file_data[]', elem.data_file);
        data.append('file_text[]', elem.text);
    });
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/process.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('process', response.data.process);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}